import React from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import style from '../../css/style.css';

const tweetResult = (props) => {
    let allTweetIds = props.tweetsInfoId
    return(
        <div className={`${style['grid-container']}`}>        
            {allTweetIds.map(({id}) => (
                <div className={`${style['grid-item']}`}>                          
                    <TwitterTweetEmbed tweetId={id} />                    
                </div>
            ))}
        </div>
    )
}

export default tweetResult;