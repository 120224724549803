import React, { Component } from 'react';
import axios from 'axios';
import Footer from '../../components/Footer/footer';
//import utilSyles from '../../css/utilities.css';
import styles from '../../css/style.css'
import Spinner from '../../components/Spinner/Spinner';
import TweetComp from '../../components/TweetComp/tweetComp';
//import Header from '../../components/Header/header';
//import ShareButton from '../../components/ShareButton/shareButton';

class Twitterbot extends Component {
    constructor(props){
        super(props);
        this.state = {
            tweetsArray: [],
            tweetsRetrieved: false,
            inProcess: false
        }
    }
        
    getTweets = async (event) => {
        event.preventDefault(); 
        const adddata = {
            wordToFind: event.target.TwitterText.value,            
        }
        this.setState({inProcess: true,
                       tweetsRetrieved: false});
        await axios.post('http://54.70.120.203:3800/api/getTweets', adddata)
            .then(response => {                
                if(response.data.message !== 'No_data'){
                    console.log(response.data.tweets);
                    this.setState({tweetsRetrieved: true,
                                    inProcess: false,
                                    tweetsArray: response.data.tweets});                    
                } else {
                    console.log('No hubo resultados')
                    this.setState({tweetsRetrieved: false,
                                    inProcess: false});                     
                }
            })
            .catch(err => {
                event.target.reset();  
                alert(err)
            })         
    }

    render(){
        let TwitterResults = <div><p>Search something!!</p></div>;
        let formElementMesage = [];
        if(this.state.inProcess){
            TwitterResults = <Spinner />;
        }
        if(this.state.tweetsRetrieved){
            for(let i=0; i < this.state.tweetsArray.length; i++){
                formElementMesage.push({
                    id: this.state.tweetsArray[i].id_str,
                })                
            }
            TwitterResults = <TweetComp tweetsInfoId={formElementMesage}/>//<div><p>DATA!!!!</p></div> //
        }else{
            TwitterResults = <div><p>No DATA</p></div>
        }        
        return(
            <div>
                <div className={`${styles['twitter-bot']}`}>
                    <h1>Semi-TwitterBot</h1>
                    <form onSubmit={this.getTweets} className={`${styles['callback-form']}`}>
                        <div className={`${styles['form-control']}`}>
                            <label htmlFor="TwitterText"></label>
                            <input type="text" name="TwitterText" id="TwitterText" 
                                placeholder="What are you looking for in Twitter?" required/>
                        </div>
                        <input type="submit" value="Submit" className={styles.btn}/>
                    </form>
                    {TwitterResults}
                </div>
                <Footer />
            </div>
        )
    }
}

export default Twitterbot;