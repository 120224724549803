import React, { Component } from 'react';
import AuxFile from '../Aux_folder/Aux_file';

class Layout extends Component{
    render(){
        return(
            <AuxFile>                
                <main>
                    {this.props.children}
                </main>                                
            </AuxFile>            
        )
    }
}

export default Layout;