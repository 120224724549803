import React from 'react'
import { NavLink } from 'react-router-dom';
import utilsStyles from '../../css/utilities.css';

const blog = (props) => {
    let allPosts = props.blogsInfo       
    return(        
        <div>
            {allPosts.map(({id, date, title, imageblog ,content}) => (                                
                <div className={utilsStyles.rowBlog}>
                    <div className={utilsStyles.column}>
                        <div className={`${utilsStyles['column-1']}`}>
                            <img src={'http://freedogstudios.com/FDSImages/BlogMainImages/'+imageblog} alt={imageblog}/> 
                            {/* <img src={require('http://citystores.org/FDSImages/BlogMainImages/'+imageblog).default} alt="Post"/>  */}
                        </div>
                    </div>
                    <div className={utilsStyles.column}>
                        <div className={`${utilsStyles['column-2']} ${utilsStyles['bg-dark']}`}>
                            <h2>{title}</h2>
                            <p className={utilsStyles.meta}>
                                {date}
                            </p>
                            <p>{content}</p>
                                <NavLink
                                    to={{
                                        pathname: '/blog/'+ id,
                                        messageArray: {
                                            msg: id
                                        }
                                    }}                                        
                                    activeStyle={{
                                        fontWeight: "bold",
                                        color: "#00ddff",
                                        "borderBottom": "#ff1d58 2px solid"
                                    }}                                        
                                >Read More</NavLink>                         
                        </div>
                    </div>
                </div>
            ))}  
        </div>     
    )
}

export default blog;