import React from 'react'
import { Helmet } from 'react-helmet';

const header = (props) => {
    let title = props.title;
    let description = props.description;
    let keywords = props.keywords;
    let url = props.url;
    let type = props.type;
    let fbtitle = props.fbtitle;
    let content = props.content;
    let imageURL = props.imageURL;
    return(        
        <Helmet>
            <meta name="description" content={description}/>
            <meta name="keywords" content={keywords}/>
            <meta name="twitter:card" content="summary"/> 
            <meta name="twitter:site" content="@FreeDogStudios1" /> 
            <meta name="twitter:title" content={fbtitle} /> 
            <meta name="twitter:description" content={content}/> 
            <meta name="twitter:image" content={imageURL}/>
            <meta property="og:url" content={url} />
            <meta property="og:type" content={type} />
            <meta property="og:title" content={fbtitle} />
            <meta property="og:description" content={content} />
            <meta property="og:image" content={imageURL} />
            <title>{title} | Free Dog Studios</title>
        </Helmet>                 
    )
}

export default header;