import React, { Component } from 'react';
import BlogComponent from '../../components/BlogComp/blogComp';
import axios from 'axios';
import NavBarComp from '../../components/NavBar/navbar_out';
import Footer from '../../components/Footer/footer';
import utilStyles from '../../css/utilities.css';
import Spinner from '../../components/Spinner/Spinner';
import Header from '../../components/Header/header';
// import ShareButton from '../../components/ShareButton/shareButton';

class Blog extends Component {
    constructor(props){
        super(props);                   
        this.state= {
            message: [],
            messageRetrived: false
        }        
    }

    componentDidMount(){
        axios.get('http://54.70.120.203:3800/api/blogInfo')
        .then(response => {            
            this.setState({message: response.data.message,
                            messageRetrived: true}); 
                               
        })
        .catch(err => {
            console.log(err)
        })        
    }    

    render(){
        let formElementMesage = [];
        let blog = (
            <Spinner />
        )

        if(this.state.messageRetrived){
            for(let i=0; i < this.state.message.length; i++){
                formElementMesage.push({
                    id: this.state.message[i].id,
                    title: this.state.message[i].title,
                    content: this.state.message[i].content,
                    imageblog: this.state.message[i].blog_image,
                    date: this.state.message[i].date
                })
            }
            blog = <BlogComponent blogsInfo={formElementMesage}/>          
        } else{
            blog = <div><p>No Data Available</p></div>
        }
        return(            
            <div>
                <Header 
                    title={"Reports"} 
                    description={"Work of Free Dog Studios"} 
                    keywords={"Reports, Analysis, Twitter, Analitics, Music, TV, Apps, Blog"}
                    url = {"http://freedogstudios.com/blogresume"}
                    type = {"article"}
                    fbtitle = {"Because data is not boring"}
                    content = {"Check our work realated to data analysis"}
                    imageURL = {"http://freedogstudios.com/FDSImages/Header_logo_web_page.jpg"}/>
                <NavBarComp />
                <div className={`${utilStyles['blogPadding']}`}>
                    <section className={`${utilStyles['flex-columns']}`}>
                        {blog}   
                    </section>                                            
                    <Footer />    
                    {/* <ShareButton />          */}
                </div>
            </div>
        )
    }
}

export default Blog