import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import NavBarComp from '../../components/NavBar/navbar';
import FooterComp from '../../components/Footer/footer';
import styles from '../../css/style.css';
import utilsStyles from '../../css/utilities.css';
import poseidonLogo from '../../assets/images/Poseidon_Logo.png';
import roomsLogo from '../../assets/images/logo_Rooms.png';
import barbamiaLogo from '../../assets/images/logo_barbamia_15_VampireDark_cortado.png'
import phoneImage from '../../assets/images/phone.jpg';
import dataImage from '../../assets/images/data.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
//import ShareButton from '../../components/ShareButton/shareButton';

class HomePage extends Component {

    constructor(props){
        super(props);
        this.state= {
            message: [],
            messageRetrived: false
        }                
        this.sendEmailContact = this.sendEmailContact.bind(this);        
    }

    componentDidMount(){
        axios.get('http://54.70.120.203:3800/api/blogJustInfo')
        .then(response => {            
            this.setState({message: response.data.message,
                            messageRetrived: true}); 
                               
        })
        .catch(err => {
            console.log(err)
        })        
    }

    sendEmailContact = async (event) => {
        event.preventDefault(); 
        const adddata = {
            email: event.target.email.value,
            name: event.target.name.value,
            comment: event.target.comment.value
        }
        await axios.post('http://54.70.120.203:3800/api/emailSendFDS', adddata)
            .then(response => {
                if((response.data === 'MesSuccess') && (response.status === 200)){
                    event.target.reset();  
                    alert('ASAP we will put in contact with you!!')
                }
            })
            .catch(err => {
                event.target.reset();  
                alert(err)
            })         
    }
    
    render(){   
        let blogInfo = (
            <div>
                <h2>OOooops!!!</h2>
                <h3>No Info yet</h3>
                <h4>25/Oct/1985</h4>   
            </div>
        ) 
        let blogInfo_Sec = (
            <div>
                <h2>OOooops!!!</h2>
                <h3>No Info yet</h3>
                <h4>25/Oct/1985</h4>   
            </div>
        )                       
        if(this.state.messageRetrived){
            blogInfo = (
                <div className={`${styles['blogInfo']}`}>
                    <h2>{this.state.message[0].title}</h2>
                    <h3>{this.state.message[0].content}</h3>
                    <h4>{this.state.message[0].date}</h4>   
                </div>            
            )
            blogInfo_Sec = (
                <div className={`${styles['blogInfo']}`}>
                    <h2>{this.state.message[1].title}</h2>
                    <h3>{this.state.message[1].content}</h3>
                    <h4>{this.state.message[1].date}</h4>  
                </div>
            )             
        }

        return(
            <div id="home">
                <header className={styles.hero}>
                    <NavBarComp />                    
                </header>
                <main>                     
                    {/* About: Icons */}                    
                    <section id="about" className={`${styles['icons']} ${utilsStyles['bg-light']}`}>
                        <div className={`${utilsStyles['flex-items']}`}>                            
                            <div className={`${styles['presentation']}`}>
                                <h3>DEVELOPMENT OF MOBILE APPLICATIONS.</h3>                                    
                                <p>Technology applied to make your life easier.</p>
                                <p>We take care of getting your company into the hands of your clients.</p>
                            </div>                            
                        </div>
                    </section>
                    {/* Services */}
                    <section id="services" className={`${styles['services']} ${utilsStyles['bg-dark']} ${utilsStyles['flex-grid']} ${utilsStyles['section-padding']}`}>
                        <header className={`${utilsStyles['section-header']}`}>
                            <h2>Our Services</h2>
                        </header>
                        <div className={utilsStyles.row}>
                            <div className={utilsStyles.column}>                                
                                <div >                                    
                                    <FontAwesomeIcon icon={['fas', 'mobile-alt']} size="3x"/>
                                    <div>
                                        <h3>Mobile Apps</h3>   
                                        <h4>Android / iOS</h4>
                                    </div>
                                </div>
                                <div>                                    
                                    <FontAwesomeIcon icon={['fas', 'laptop-code']} size="3x"/>
                                    <div>
                                        <h3>Web Apps</h3>
                                        <h4>ReactJS / NextJS</h4>
                                    </div>
                                </div>
                            </div>
                            <div className={utilsStyles.column}>
                                <div>
                                    <FontAwesomeIcon icon={['fab', 'twitter']} size="3x"/>                                    
                                    <div>
                                        <h3>Social Media Analytics</h3>
                                        <h4>Twitter / Web Scrapping</h4>
                                    </div>
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={['fab', 'facebook-messenger']} size="3x"/>                                    
                                    <div>
                                        <h3>Chatbots</h3>
                                        <h4>Messenger</h4>
                                    </div>
                                </div>  
                            </div>
                            <div className={utilsStyles.column}>
                                <div>
                                    <FontAwesomeIcon icon={['fas', 'cubes']} size="3x"/>                                    
                                    <div>
                                        <h3>Backend Infrastructure</h3>                                    
                                        <h4>AWS / NodeJs / Python</h4>
                                    </div>
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={['fas', 'laptop-house']} size="3x"/>                                    
                                    <div>
                                        <h3>Connected Devices</h3>                                    
                                        <h4>Domotics / Telemetrics</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>                    
                    {/* Applications */}
                    <section id="applications" className={`${styles['services']} ${utilsStyles['bg-light']} ${utilsStyles['flex-grid']} ${utilsStyles['section-padding']}`}>
                        <header className={`${utilsStyles['section-header']} ${utilsStyles['dark']}`}>
                            <h2>Applications</h2>
                        </header>
                        <div className={utilsStyles.row}>
                            <div className={utilsStyles.column}>
                                <div >
                                    <a href="http://poseidon.rocks" >
                                        <img src={poseidonLogo} alt="" />
                                    </a>                    
                                </div>
                                <div>
                                    <h2>Poseidon Project</h2>
                                </div>
                            </div>
                            <div className={utilsStyles.column}>
                                <div >
                                    <a href="http://irooms.mx" >
                                        <img src={roomsLogo} alt="" />
                                    </a>                    
                                </div>
                                <div>
                                    <h2>Rooms</h2>
                                </div>                                
                            </div>
                            <div className={utilsStyles.column}>
                                <div >
                                    <a href="http://barbamia.mx" >
                                        <img src={barbamiaLogo} alt="" />
                                    </a>                    
                                </div>
                                <div>
                                    <h2>Barbamia</h2>
                                </div>
                            </div>                            
                        </div>
                    </section>
                    {/* Reports */}
                    <section id="reports" className={`${styles['blog']} ${utilsStyles['flex-columns']} ${utilsStyles['flex-reverse']}`}>
                        <div className={utilsStyles.row}>
                            <div className={utilsStyles.column}>
                                <div className={`${utilsStyles['column-1']}`}>
                                    <img src={dataImage} alt=""/>
                                </div>
                            </div>
                            <div className={utilsStyles.column}>
                                <div className= {`${utilsStyles['column-2']} ${utilsStyles['bg-secondary']}`}>
                                    {blogInfo}

                                </div>
                                <div className= {`${utilsStyles['column-2']} ${utilsStyles['bg-secondary']}`}>
                                    {blogInfo_Sec}
                                </div>            
                                <div className= {`${utilsStyles['column-2']} ${utilsStyles['bg-secondary']}`}>
                                    <NavLink
                                            to={{
                                                pathname: '/blogresume',
                                                messageArray: {
                                                    msg: this.state.message
                                                }
                                            }}                                        
                                            activeStyle={{
                                                fontWeight: "bold",
                                                color: "#D01A7D",
                                                "borderBottom": "#D01A7D 2px solid"
                                            }}                                        
                                        >Read More</NavLink>                                    
                                </div>                    
                            </div>
                        </div>
                    </section>
                    {/* Contact */}
                    <section id="contact" className={`${styles['contact']} ${utilsStyles['flex-columns']}`}>
                        <div className={utilsStyles.row}>
                            <div className={utilsStyles.column}>
                                <div className={`${utilsStyles['column-1']}`}>
                                    <img src={phoneImage} alt=""/>
                                </div>
                            </div>
                            <div className={utilsStyles.column}>
                                <div className= {`${utilsStyles['column-2']} ${utilsStyles['bg-light']}`}>
                                    <h2>Request Callback</h2>
                                    <form onSubmit={this.sendEmailContact} className={`${styles['callback-form']}`}>
                                        <div className={`${styles['form-control']}`}>
                                            <label htmlFor="name"></label>
                                            <input type="text" name="name" id="name" placeholder="Name" required/>
                                        </div>
                                        <div className={`${styles['form-control']}`}>
                                            <label htmlFor="email"></label>
                                            <input type="email" name="email" id="email" placeholder="E-mail" required/>
                                        </div>
                                        <div className={`${styles['form-control']}`}>
                                            <label htmlFor="comment"></label>
                                            <input type="text" name="comment" id="comment" placeholder="Comment" required/>
                                        </div>
                                        <input type="submit" value="Submit" className={styles.btn}/>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>                           
                </main>
                <FooterComp />
               
            </div>    
        )
    }
}

export default HomePage;