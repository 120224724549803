import React, { Component } from 'react';
import axios from 'axios';
import Footer from '../../components/Footer/footer';
import utilStyles from '../../css/utilities.css';
import Spinner from '../../components/Spinner/Spinner';
import Header from '../../components/Header/header';
import ShareButton from '../../components/ShareButton/shareButton';

class BlogFull extends Component {  
    constructor(props){
        super(props)           
        this.state= {
            htmlString: "<div></div>",
            loading: true,
            blogImage: '',
            content: ''
        }       
    }

    componentDidMount(){ 
        axios.get('http://54.70.120.203:3800/api' + this.props.location.pathname)
        .then(response => {                      
            if(response.data.message[0].htmlString){
                this.setState({htmlString:response.data.message[0].htmlString,
                                loading: false,
                                blogImage: 'http://freedogstudios.com/FDSImages/BlogMainImages/' + response.data.message[0].blog_image,
                                content: response.data.message[0].content})
            }else{
                this.setState({htmlString:"<div><p>Wrong URL, please go back</p></div>",
                    loading: false,
                    blogImage: 'http://freedogstudios.com/FDSImages/Header_logo_web_page.jpg',
                    content: 'We take care of getting your company into the hands of your clients.'})                
            }            
        })
        .catch(err => {            
            this.setState({htmlString:"<div><p>Wrong URL, please go back</p></div>",
            loading: false,
            blogImage: 'http://freedogstudios.com/FDSImages/Header_logo_web_page.jpg',
            content: 'We take care of getting your company into the hands of your clients.'})             
        })
    }

    render(){
        let showBlog = (
            <Spinner />
        )
        if(!this.state.loading){
            showBlog = (
                <div dangerouslySetInnerHTML={{__html: this.state.htmlString}} />
            )
        } 
        return(
            <div>
                <Header 
                    title={"Reports"} 
                    description={"Work of Free Dog Studios"} 
                    keywords={"Reports, Analysis, Twitter, Analitics, Music, TV, Apps, Blog"}
                    url = {"http://freedogstudios.com" + this.props.location.pathname}
                    type = {"article"}
                    fbtitle = {"Because data is not boring"}
                    content = {this.state.content}
                    imageURL = {this.state.blogImage}/>
                <div className={`${utilStyles['blogPadding']}`}>                    
                    {showBlog}    
                    <Footer /> 
                    <ShareButton />                   
                </div>
            </div>
        )
    }
}

export default BlogFull;