import React from 'react'
import { FacebookShareButton, TwitterShareButton, TwitterIcon, FacebookIcon } from 'react-share';
import utilsStyles from '../../css/utilities.css';

const shareButton = () => {    
    return(        
        <div className={`${utilsStyles['share-button']}`}>
            <TwitterShareButton
                url={'citystores.org/'}
                quote={"Go to our website to get more information."}
                hashtag={'#DataForFun'} >
                <TwitterIcon size={32} round={true}/>
            </TwitterShareButton>            
            <FacebookShareButton
                url={'citystores.org/'}
                quote={"Go to our website to get more information."}
                hashtag={'#DataForFun'}>
                <FacebookIcon size={32} round={true}/>
            </FacebookShareButton>                        
        </div>                
    )
}

export default shareButton;