import React from 'react'
import styles from '../../css/style.css';
import ShareButton from '../ShareButton/shareButton';

const navbar = () => {    
    return(        
        <div className={styles.navbar}>
            <h1 className={styles.logo}>Free Dog Studios</h1>
            <nav>
                <ul>
                    <li><a href="/">Home</a></li>                            
                    <li><a href="/#contact">Contact</a></li>
                    <li><ShareButton /></li>
                </ul>
            </nav>
        </div>                
    )
}

export default navbar;