import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faTwitter, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { faMobileAlt, faLaptopCode, faCubes, faLaptopHouse} from '@fortawesome/free-solid-svg-icons';

library.add(
    faFacebookF,
    faTwitter,
    faFacebookMessenger,
    faMobileAlt,
    faLaptopCode,
    faCubes,
    faLaptopHouse,
);