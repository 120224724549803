import React, { Component } from 'react';
// eslint-disable-next-line
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import Layout from './hoc/Layout/Layout';
import HomePage from './containers/HomePage/Homepage';
import Blog from './containers/BlogPage/BlogPage';
import BlogFull from './containers/BlogPage/BlogFull';
import Twitterbot from './containers/TwitterbotPage/Twitterbot';
//import asyncComponent from '../hoc/asyncComponent/asyncComponent';
import './share/fontawesome';

class App extends Component{
  
  render(){    
    let routes = (
      <Switch>
        <Route path='/twitterbot' component={Twitterbot}/>
        <Route path='/blog/:blogId' component={BlogFull} />
        <Route path='/blogresume' component={Blog} />
        <Route path='/' exact component={HomePage} />
        <Redirect to='/' />
      </Switch>
    )
    return(
      <Layout>
        {routes}
      </Layout>
    )
  }
}

export default App;
