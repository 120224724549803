import React from 'react'
import styles from '../../css/style.css';
import utilsStyles from '../../css/utilities.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const footer = () => {    
    return(        
        <footer className={`${styles['footer']} ${utilsStyles['bg-dark']}`}>            
            <div className={`${styles['social']}`}>
                <a href="https://twitter.com/FreeDogStudios1" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']} size="2x"/></a>
                <a href="https://www.facebook.com/Free-Dog-Studios-103537538186866/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'facebook-f']} size="2x"/></a>
            </div>
            <div>
                <p>Free Dog Studios, All Rights Reserved 2021</p>
                <p>Toluca, Mexico</p>
            </div>
        </footer>                  
    )
}

export default footer;